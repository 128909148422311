import React from "react";
import '../styles/footer.css';

export default function Footer() {
    return (
        <div>
            <footer>
                <p><small>©2023 Farai Mutukumira</small></p>
            </footer>
        </div>
    )
}